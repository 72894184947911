import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Typography,
    CircularProgress,
    Alert,
    Tooltip,
    IconButton,
    Paper,
    CardContent,
    Box,
    Stack,
    Link,
    Avatar // Add Avatar component for image display
} from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Link as RouterLink } from 'react-router-dom';

function StockRankings() {
    const [rankings, setRankings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStockRankings = async () => {
            try {
                const session = await fetchAuthSession();
                console.log("Session object:", session); // Debugging statement

                if (session && session.tokens && session.tokens.idToken) {
                    const idToken = session.tokens.idToken.toString();
                    console.log("ID Token:", idToken); // Debugging statement

                    const response = await fetch('https://api.capitolgains.io/user/rankings', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${idToken}`  // Use the ID token here
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const data = await response.json();
                    console.log('API Response Data:', data);

                    if (data.rankings && Array.isArray(data.rankings)) {
                        setRankings(data.rankings);
                    } else {
                        throw new Error('Unexpected API response format');
                    }
                } else {
                    throw new Error('Session is invalid or missing ID token');
                }
            } catch (err) {
                console.error('Error fetching stock rankings:', err.message);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchStockRankings();
    }, []);

    if (loading) {
        return (
            <Container>
                <Grid container justifyContent="center">
                    <CircularProgress />
                </Grid>
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Alert severity="error">Error: {error.message}</Alert>
            </Container>
        );
    }

    return (
        <Container>
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Typography variant="h4" gutterBottom>
                        Stock Rankings
                    </Typography>
                </Grid>
                <Grid item>
                    <Tooltip
                        title={
                            <>
                                <Typography variant="body2" color="inherit">
                                    Learn more about how stock rankings are determined, including the factors that influence them.
                                </Typography>
                                <Link component={RouterLink} to="/stock-ranking-info" underline="hover" color="inherit">
                                    Click here for more details.
                                </Link>
                            </>
                        }
                    >
                        <IconButton>
                            <InfoOutlined />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                {rankings.length === 0 ? (
                    <Grid item xs={12}>
                        <Alert severity="info">No rankings available.</Alert>
                    </Grid>
                ) : (
                    rankings.map((stock, index) => {
                        const scoreStyle = {
                            color: stock.score >= 1 ? 'green' : 'red',
                        };

                        const formattedScore = stock.score >= 1 ? `+${(stock.score - 1).toFixed(2)}` : `${(stock.score - 1).toFixed(2)}`;

                        const logoUrl = `https://img.logo.dev/ticker/${stock.ticker}?token=pk_Fy4ZV5RMQEKzNJfSy9h6BA`; // Image URL

                        return (
                            <Grid item xs={12} md={6} key={index}>
                                <Paper elevation={3} sx={{ borderRadius: 2 }}>
                                    <Box
                                        sx={{
                                            backgroundColor: '#1976d2',
                                            padding: 2,
                                            borderTopLeftRadius: '8px',
                                            borderTopRightRadius: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Avatar
                                            src={logoUrl}
                                            alt={`${stock.ticker} logo`}
                                            sx={{ width: 50, height: 50, marginRight: 2 }} // Adjust size as needed
                                        />
                                        <Box>
                                            <Typography variant="h6" style={{ color: 'white' }}>
                                                <Link
                                                    href={`https://finance.yahoo.com/quote/${stock.ticker}`}
                                                    target="_blank"
                                                    rel="noopener"
                                                    color="inherit"
                                                    underline="hover"
                                                    style={{ color: 'white' }}
                                                >
                                                    {`${stock.rank}. ${stock.ticker}`}
                                                </Link>
                                            </Typography>
                                            <Typography variant="subtitle2" sx={scoreStyle} style={{ color: 'white' }}>
                                                Score: {formattedScore}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <CardContent>
                                        <Stack spacing={1}>
                                            <Typography variant="body2" color="textSecondary">
                                                <strong>Congress Members Purchased:</strong> {stock.congress_members_purchased.length > 0 ? stock.congress_members_purchased.join(', ') : 'None'}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                <strong>Congress Members Sold:</strong> {stock.congress_members_sold.length > 0 ? stock.congress_members_sold.join(', ') : 'None'}
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Paper>
                            </Grid>
                        );
                    })
                )}
            </Grid>
        </Container>
    );
}

export default StockRankings;