import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Typography,
    CircularProgress,
    Alert,
    Box,
    Stack,
    Paper,
    Divider,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Switch,
    FormControlLabel
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';  // Import Circle icon for the party dot
import { fetchAuthSession } from 'aws-amplify/auth';
import InfoTooltip from '../components/InfoTooltip'


function TradingLeaderboard() {
    const [leaders, setLeaders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortBy, setSortBy] = useState('default'); // Default to score-based ranking
    const [showActiveOnly, setShowActiveOnly] = useState(true);
    const purchaseThreshold = 10; // Example threshold for the minimum number of purchases

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const session = await fetchAuthSession();
                if (session && session.tokens && session.tokens.idToken) {
                    const idToken = session.tokens.idToken.toString();
                    fetch('https://api.capitolgains.io/user/congress', {
                        headers: {
                            Authorization: `Bearer ${idToken}`,
                        },
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.json();
                        })
                        .then((data) => {
                            const enrichedLeaders = (data.trader_stats || []).map(member => {
                                const bullishTransactions = member.bullish_transactions || {};
                                const avgReturns = member.avg_returns || {};
                                const stocksReturns = avgReturns.stocks || {};
                                const purchaseROI = stocksReturns.purchase ? stocksReturns.purchase.roi : 0;
                                const purchaseCount = bullishTransactions.count || 0;
                                const accuracy = bullishTransactions.accuracy || 0;

                                // Calculate the score based on purchase ROI and accuracy, adjust as needed
                                const score = (purchaseROI * 100) * accuracy;

                                return {
                                    ...member,
                                    purchaseCount,
                                    score,
                                };
                            });

                            // Filter out members with insufficient purchases and sort by score by default
                            const filteredAndRankedLeaders = enrichedLeaders
                                .filter(member => member.purchaseCount >= purchaseThreshold)
                                .sort((a, b) => b.score - a.score);

                            setLeaders(filteredAndRankedLeaders);
                            setLoading(false);
                        })
                        .catch((error) => {
                            setError(error);
                            setLoading(false);
                        });
                } else {
                    throw new Error('Session is invalid or missing ID token');
                }
            } catch (error) {
                setError(new Error('User is not authenticated or token is missing'));
                setLoading(false);
            }
        };

        fetchSession();
    }, []);

    // Handle sorting
    const handleSortChange = (event) => {
        setSortBy(event.target.value);
    };

    // Handle toggle for showing only active members
    const handleToggleActive = (event) => {
        setShowActiveOnly(event.target.checked);
    };

    // Filter and sort the leaders list based on user selections
    const filteredLeaders = leaders
        .filter((member) => {
            if (showActiveOnly) {
                return member.member_details?.current_member === true;
            }
            return true;
        })
        .sort((a, b) => {
            if (sortBy === 'default') {
                return b.score - a.score; // Keep default score-based ranking
            }

            const getSortValue = (member) => {
                const avgReturns = member.avg_returns || {};
                const stocksReturns = avgReturns.stocks || {};
                const purchaseROI = stocksReturns.purchase ? stocksReturns.purchase.roi : 0;
                const purchaseCount = member.purchaseCount || 0;
                const accuracy = member.bullish_transactions?.accuracy || 0;
                const purchaseVolume = member.bullish_transactions?.volume || 0;

                switch (sortBy) {
                    case 'averageROI':
                        return purchaseROI;
                    case 'hitRate':
                        return accuracy;
                    case 'numberOfPurchases':
                        return purchaseCount;
                    case 'averageStockPurchase':
                        return purchaseVolume;
                    default:
                        return member.score;
                }
            };

            return getSortValue(b) - getSortValue(a); // Descending order
        });

    if (loading) {
        return (
            <Container>
                <Grid container justifyContent="center">
                    <CircularProgress />
                </Grid>
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Alert severity="error">Error: {error.message}</Alert>
            </Container>
        );
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom sx={{ color: '#ffffff' }}>
                Congressional Stock Trading Leaderboard
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    gap: 1,
                    marginBottom: 2,
                }}
            >
                <FormControl variant="outlined" sx={{ minWidth: { xs: '100%', sm: 200 } }}>
                    <InputLabel id="sort-by-label">Sort By</InputLabel>
                    <Select
                        labelId="sort-by-label"
                        value={sortBy}
                        onChange={handleSortChange}
                        label="Sort By"
                    >
                        <MenuItem value="default">Capitol Gains Score</MenuItem>
                        <MenuItem value="averageROI">Annual Return</MenuItem>
                        <MenuItem value="hitRate">Hit Rate</MenuItem>
                        <MenuItem value="numberOfPurchases">Number of Purchases</MenuItem>
                        <MenuItem value="averageStockPurchase">Average Stock Purchase</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showActiveOnly}
                            onChange={handleToggleActive}
                            color="primary"
                        />
                    }
                    label="Show Only Active Members"
                    sx={{ marginLeft: { xs: 0, sm: 2 }, marginTop: { xs: 1, sm: 0 } }}
                />
            </Box>
            <Grid container spacing={3}>
                {filteredLeaders.length === 0 ? (
                    <Grid item xs={12}>
                        <Alert severity="info">No data available.</Alert>
                    </Grid>
                ) : (
                    filteredLeaders.map((member, index) => {
                        const bullishTransactions = member.bullish_transactions || {};
                        const avgReturns = member.avg_returns || {};
                        const stocksReturns = avgReturns.stocks || {};
                        const purchaseROI = stocksReturns.purchase ? stocksReturns.purchase.roi : null;
                        const purchaseCount = bullishTransactions.count || 0;
                        const accuracy = bullishTransactions.accuracy || null;
                        const purchaseVolume = bullishTransactions.volume || null;

                        const notificationHistory = member.notification_history || {};
                        const notificationFrequency = notificationHistory.notification_frequency || 'N/A';
                        const transactionFrequency = notificationHistory.transaction_frequency || 'N/A';
                        const avgNotificationDelay = notificationHistory.avg_notification_delay || 'N/A';

                        const roiPercent = purchaseROI >= 1
                            ? ((purchaseROI - 1) * 100).toFixed(2)
                            : ((purchaseROI - 1) * 100).toFixed(2);

                        const formattedVolume = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(purchaseVolume);

                        const dynamicRank = index + 1;
                        const roiStyle = {
                            color: purchaseROI >= 1 ? '#76ff03' : '#ff1744',
                        };
                        const formattedRoiPercent = purchaseROI >= 1 ? `+${roiPercent}%` : `${roiPercent}%`;

                        const memberDetails = member.member_details || {};
                        const memberImage = memberDetails.image || 'https://via.placeholder.com/150';
                        const memberWebsite = memberDetails.website || '#';
                        const memberParty = memberDetails.party || 'Unknown Party';
                        const memberState = memberDetails.state || 'Unknown State';
                        const currentMember = memberDetails.current_member;
                        const currentMemberText = currentMember === true ? "Yes" : currentMember === false ? "No" : "Unknown";
                        const partyColor = memberParty === 'Democratic' ? '#1976d2' : memberParty === 'Republican' ? '#d32f2f' : '#757575';

                        return (
                            <Grid item xs={12} md={6} key={index}>
                                <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden', backgroundColor: '#333333' }}>
                                    <Box sx={{ padding: 2, backgroundColor: '#424242', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box>
                                            <Typography variant="h6" sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                                                <a href={memberWebsite} target="_blank" rel="noopener noreferrer" style={{ color: '#ffffff', textDecoration: 'none' }}>
                                                    {member.name}
                                                </a>
                                            </Typography>
                                            <Box display="flex" alignItems="center">
                                                <CircleIcon sx={{ fontSize: 16, color: partyColor, marginRight: 0.6 }} />
                                                <Typography variant="subtitle2" sx={{ color: '#ffffff' }}>
                                                    {memberParty} - {memberState}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Typography variant="h4" sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                                            #{dynamicRank}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ borderColor: '#616161' }} />
                                    {/* Main Content Section */}
                                    <Box sx={{ display: 'flex', flexDirection: 'row', padding: 2, alignItems: 'flex-start', backgroundColor: '#333333' }}>
                                        {/* Stats Section on the Left */}
                                        <Box sx={{ flex: 2, color: '#bdbdbd' }}>
                                            <Stack spacing={1}>
                                                <Typography variant="body2" sx={roiStyle}>
                                                    <strong>Average Annual Return:</strong> {formattedRoiPercent}
                                                    <InfoTooltip title="The average annual percentage return of the investments made." />
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Hit Rate:</strong> {(accuracy * 100).toFixed(2)}%
                                                    <InfoTooltip title="The percentage of trades that were successful or profitable." />
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Number of Purchases:</strong> {purchaseCount}
                                                    <InfoTooltip title="The total number of stock purchases made." />
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Average Stock Purchase:</strong> {formattedVolume}
                                                    <InfoTooltip title="The average dollar amount spent per stock purchase." />
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Current Member:</strong> {currentMemberText}
                                                    <InfoTooltip title="Indicates whether the member is currently in office." />
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Notification Frequency:</strong> {notificationFrequency}
                                                    <InfoTooltip title="How often notifications are sent regarding trades." />
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Transaction Frequency:</strong> {transactionFrequency}
                                                    <InfoTooltip title="The average frequency of stock transactions made." />
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Average Notification Delay:</strong> {avgNotificationDelay}
                                                    <InfoTooltip title="The average time delay between a trade and its notification." />
                                                </Typography>
                                            </Stack>
                                        </Box>
                                        {/* Portrait Image Section on the Right */}
                                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '150px', height: '200px', overflow: 'hidden', backgroundColor: '#424242' }}>
                                            <Box
                                                component="img"
                                                src={memberImage}
                                                alt="Profile Picture"
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    borderRadius: '8px'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                        );
                    })
                )}
            </Grid>
        </Container>
    );
}

export default TradingLeaderboard;