import React from 'react';
import { Container, Typography, Box, Button, Grid, Card, CardContent, CardActions } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import BackgroundAnimation from '../components/BackgroundAnimation';
import CongressIcon from '@mui/icons-material/Gavel';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import StockIcon from '@mui/icons-material/TrendingUp';
import InsightsIcon from '@mui/icons-material/Insights';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StarsIcon from '@mui/icons-material/Stars';
import '../App.css';

// Custom styles for Cards
const FeatureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow: '0 8px 16px rgba(255, 255, 255, 0.1)',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.paper,
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 16px 32px rgba(255, 255, 255, 0.15)',
  },
}));

const Home = ({ dollarsEnabled }) => {
  return (
    <div>
      <BackgroundAnimation dollarsEnabled={dollarsEnabled} />

      {/* Hero Section */}
      <Container maxWidth="lg" sx={{ paddingTop: 8, paddingBottom: 8, textAlign: 'center', backgroundColor: 'rgba(18, 18, 18, 0.9)', borderRadius: 3, marginTop: 4 }}>
        <Typography variant="h3" gutterBottom sx={{ fontWeight: '700', color: '#90caf9', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)' }}>
          Track, Learn, Invest: Trade Like Congress.
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontSize: '1.2rem', color: '#ffffff', marginBottom: 4 }}>
          CapitolGains.io offers <Box component="span" sx={{ fontWeight: 'bold', color: '#90caf9' }}>free access</Box> to historical and up-to-date stock trades made by U.S. Congress members.
          <br />
          Our platform empowers you with transparent, fact-based insights, helping you stay informed about the financial activities of your representatives.
        </Typography>
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/stocks"
            sx={{
              width: { xs: '100%', sm: 'auto' },
              paddingX: 3,
              paddingY: 1,
              boxShadow: '0 4px 8px rgba(255, 255, 255, 0.2)',
              transition: 'background-color 0.3s ease',
              '&:hover': { backgroundColor: '#1e88e5' }
            }}
          >
            Get Started for Free
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to="/about"
            sx={{
              width: { xs: '100%', sm: 'auto' },
              paddingX: 3,
              paddingY: 1,
              borderColor: '#f48fb1',
              color: '#f48fb1',
              '&:hover': { borderColor: '#f06292', color: '#f06292' }
            }}
          >
            Learn More About Us
          </Button>
        </Box>
      </Container>

      {/* Features and Benefits Section */}
      <Container maxWidth="lg" sx={{ paddingTop: 8, paddingBottom: 8, backgroundColor: 'rgba(24, 24, 24, 0.9)', borderRadius: 3, boxShadow: '0 4px 12px rgba(255, 255, 255, 0.05)', marginTop: 8 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9', textAlign: 'center', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)' }}>
          Explore Congress Trading Data
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <FeatureCard>
              <CardContent sx={{ padding: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9', display: 'flex', alignItems: 'center' }}>
                  <CongressIcon sx={{ fontSize: 30, color: '#90caf9', verticalAlign: 'middle', marginRight: 1 }} />
                  Congress Trade Tracking
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: '#ffffff' }}>
                  View the most recent trades made by U.S. Congress members and monitor their financial activities.
                </Typography>
              </CardContent>
            </FeatureCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FeatureCard>
              <CardContent sx={{ padding: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9', display: 'flex', alignItems: 'center' }}>
                  <LeaderboardIcon sx={{ fontSize: 30, color: '#90caf9', verticalAlign: 'middle', marginRight: 1 }} />
                  Congress Leaderboard
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: '#ffffff' }}>
                  Discover which Congress members are the most successful stock traders with our leaderboard feature.
                </Typography>
              </CardContent>
            </FeatureCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FeatureCard>
              <CardContent sx={{ padding: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9', display: 'flex', alignItems: 'center' }}>
                  <StockIcon sx={{ fontSize: 30, color: '#90caf9', verticalAlign: 'middle', marginRight: 1 }} />
                  Stock Rankings
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: '#ffffff' }}>
                  Access rankings of stocks based on recent purchase and sale activities by Congress members.
                </Typography>
              </CardContent>
            </FeatureCard>
          </Grid>
        </Grid>
      </Container>

      {/* What CapitolGains.io Offers Section */}
      <Container maxWidth="lg" sx={{ paddingTop: 8, paddingBottom: 8, textAlign: 'center', backgroundColor: 'rgba(24, 24, 24, 0.9)', borderRadius: 3, boxShadow: '0 4px 12px rgba(255, 255, 255, 0.05)', marginTop: 8 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)' }}>
          What CapitolGains.io Offers
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', color: '#ffffff', marginBottom: 4 }}>
          Discover the unique features and benefits that set us apart from the official House and Senate websites.
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <InsightsIcon sx={{ fontSize: 40, color: '#90caf9', marginBottom: 1 }} />
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9' }}>
              Comprehensive Data
            </Typography>
            <Typography variant="body2" paragraph sx={{ color: '#ffffff' }}>
              Access detailed historical and up-to-date stock trade data, including stock prices and their changes over time.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DataUsageIcon sx={{ fontSize: 40, color: '#90caf9', marginBottom: 1 }} />
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9' }}>
              User-Friendly Interface
            </Typography>
            <Typography variant="body2" paragraph sx={{ color: '#ffffff' }}>
              Easily navigate complex financial disclosures with our intuitive and straightforward platform.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TrendingUpIcon sx={{ fontSize: 40, color: '#90caf9', marginBottom: 1 }} />
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9' }}>
              In-Depth Analytics
            </Typography>
            <Typography variant="body2" paragraph sx={{ color: '#ffffff' }}>
              Monitor and analyze Congress members' trading activities, detect patterns, and gain insights into their financial behaviors.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/* Subscription Tiers and Pricing Section */}
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)', textAlign: 'center', paddingTop: 8, marginTop: 8 }}>
        Choose Your Plan
      </Typography>
      <Grid container spacing={4} justifyContent="center" sx={{ paddingBottom: 8 }}>
        <Grid item xs={12} md={5}>
          <FeatureCard sx={{ backgroundColor: 'rgba(36, 36, 36, 0.9)' }}>
            <CardContent sx={{ padding: 3 }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9' }}>
                Free Plan
              </Typography>
              <Typography variant="body2" paragraph sx={{ color: '#ffffff' }}>
                Get access to historical stock trades made by Congress members, with a 1-month delay for the latest trade reportings.
              </Typography>
            </CardContent>
            <CardActions sx={{ paddingBottom: 2, paddingX: 3 }}>
              <Button variant="outlined" fullWidth color="primary" component={Link} to="/sign-up" sx={{ fontWeight: 'bold', '&:hover': { backgroundColor: '#424242', borderColor: '#90caf9', color: '#90caf9' } }}>
                Sign Up for Free
              </Button>
            </CardActions>
          </FeatureCard>
        </Grid>
        <Grid item xs={12} md={5}>
          <FeatureCard sx={{ backgroundColor: 'rgba(36, 36, 36, 0.9)' }}>
            <CardContent sx={{ padding: 3 }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9', display: 'flex', alignItems: 'center' }}>
                Premium Plan
                <StarsIcon sx={{ fontSize: 30, color: '#FFD700', marginLeft: 1 }} />
              </Typography>
              <Typography variant="body2" paragraph sx={{ color: '#ffffff' }}>
                Gain full access to up-to-date trades, stock rankings, in-depth analysis, and more.
              </Typography>
            </CardContent>
            <CardActions sx={{ paddingBottom: 2, paddingX: 3 }}>
              <Button variant="contained" fullWidth color="primary" component={Link} to="/pricing" sx={{ fontWeight: 'bold', boxShadow: '0 4px 8px rgba(255, 255, 255, 0.1)', '&:hover': { backgroundColor: '#1e88e5' } }}>
                Upgrade Now
              </Button>
            </CardActions>
          </FeatureCard>
        </Grid>
      </Grid>

      {/* About Us and Mission Statement */}
      <Container maxWidth="lg" sx={{ paddingTop: 8, paddingBottom: 8, textAlign: 'center', backgroundColor: 'rgba(24, 24, 24, 0.9)', borderRadius: 3, boxShadow: '0 4px 12px rgba(255, 255, 255, 0.05)', marginTop: 8 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)' }}>
          About CapitolGains.io
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', color: '#ffffff' }}>
          Our goal is to offer a valuable service that helps users stay informed about the stock trading activities of U.S. Congress members through accessible, fact-based insights.
        </Typography>
        <Button variant="outlined" color="secondary" component={Link} to="/about" sx={{ paddingX: 3, paddingY: 1, borderColor: '#f48fb1', color: '#f48fb1', '&:hover': { borderColor: '#f06292', color: '#f06292' } }}>
          Learn More About Us
        </Button>
      </Container>

      {/* How It Works Section */}
      <Typography variant="h4" gutterBottom textAlign="center" sx={{ fontWeight: 'bold', color: '#90caf9', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)', paddingTop: 8, marginTop: 8 }}>
        How It Works
      </Typography>
      <Grid container spacing={4} justifyContent="center" sx={{ paddingBottom: 8 }}>
        <Grid item xs={12} sm={4} textAlign="center">
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9' }}>
            1. Sign Up for Free
          </Typography>
          <Typography variant="body2" sx={{ color: '#ffffff' }}>
            Create your account in just a few minutes to start exploring our platform.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} textAlign="center">
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9' }}>
            2. Explore Recent Trades
          </Typography>
          <Typography variant="body2" sx={{ color: '#ffffff' }}>
            View recent stock trades by U.S. Congress members and analyze their financial moves.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} textAlign="center">
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9' }}>
            3. Upgrade for Full Access
          </Typography>
          <Typography variant="body2" sx={{ color: '#ffffff' }}>
            Upgrade to our paid plan for real-time updates and comprehensive insights.
          </Typography>
        </Grid>
      </Grid>

      {/* Call to Action (CTA) */}
      <Container maxWidth="lg" sx={{ paddingTop: 8, paddingBottom: 8, textAlign: 'center', backgroundColor: 'rgba(18, 18, 18, 0.9)', borderRadius: 3, boxShadow: '0 4px 12px rgba(255, 255, 255, 0.05)', marginTop: 8 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)' }}>
          Ready to Dive In?
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 4,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/sign-up"
            sx={{
              width: { xs: '100%', sm: 'auto' },
              paddingX: 3,
              paddingY: 1,
              boxShadow: '0 4px 8px rgba(255, 255, 255, 0.2)',
              transition: 'background-color 0.3s ease',
              '&:hover': { backgroundColor: '#1e88e5' },
            }}
          >
            Sign Up for Free
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to="/pricing"
            sx={{
              width: { xs: '100%', sm: 'auto' },
              paddingX: 3,
              paddingY: 1,
              borderColor: '#f48fb1',
              color: '#f48fb1',
              '&:hover': { borderColor: '#f06292', color: '#f06292' },
            }}
          >
            View Subscription Plans
          </Button>
        </Box>
      </Container>

      <Typography variant="h4" gutterBottom textAlign="center" sx={{ fontWeight: 'bold', color: '#90caf9', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)', paddingTop: 8, marginTop: 8 }}>
        FAQ
      </Typography>
      <Container maxWidth="lg" sx={{ paddingBottom: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9' }}>
              How Are Congress Stock Trades Collected?
            </Typography>
            <Typography variant="body2" sx={{ color: '#ffffff' }}>
              We collect stock trade disclosures directly from publicly available sources, including the House and Senate websites, where members of Congress are required to file their stock trades. Our platform aggregates and enriches this data, ensuring you have access to the latest information in a user-friendly format.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9' }}>
              How Often Does Congress Report Trades?
            </Typography>
            <Typography variant="body2" sx={{ color: '#ffffff' }}>
              Members of Congress must report their stock trades within 45 days of the transaction by law under the STOCKS Act. While the delay can impact short-term trading strategies, our focus on long-term trends helps mitigate this lag, offering a clearer picture of trading patterns over time.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9' }}>
              How Our Stock Ranking Algorithm Works
            </Typography>
            <Typography variant="body2" sx={{ color: '#ffffff' }}>
              Our stock ranking algorithm analyzes the purchase and sale activities of Congress members, focusing on long-term holding potential (1 year or more). By evaluating the historical performance of these trades, we rank stocks based on their likelihood of providing strong returns over extended periods. The 45-day notification window doesn't affect the accuracy of our algorithm, as it's tailored for long-term insights rather than short-term trades.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#90caf9' }}>
              Key Details About the STOCKS Act
            </Typography>
            <Typography variant="body2" sx={{ color: '#ffffff' }}>
              The STOCKS Act (Stop Trading on Congressional Knowledge Act) was enacted in 2012 to combat insider trading by requiring members of Congress to disclose their financial transactions within 45 days. It aims to increase transparency and hold lawmakers accountable for their stock trades. However, gaps in enforcement and delays in reporting mean that full transparency is not always immediate, making our platform an essential tool for tracking these activities.
            </Typography>
          </Grid>
        </Grid>
      </Container>

    </div>
  );
}

export default Home;
