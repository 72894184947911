import React, { useState, useCallback, useEffect } from 'react';
import {
    Container,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Typography,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Alert,
    Avatar, // Import Avatar to display the stock logo
} from '@mui/material';
import { fetchAuthSession } from 'aws-amplify/auth';

function RecentTrades() {
    const currentYear = new Date().getFullYear();
    const [trades, setTrades] = useState([]);
    const [filters, setFilters] = useState({
        year: currentYear, 
        first_name: '',
        last_name: '',
        chamber: '',
        asset_code: '',
        transaction: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchTrades = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const session = await fetchAuthSession();
            console.log("Session object:", session); // Debugging statement

            if (session && session.tokens && session.tokens.idToken) {
                const idToken = session.tokens.idToken.toString(); // Use ID token instead of access token
                console.log("ID Token:", idToken); // Debugging statement

                const payload = Object.fromEntries(
                    Object.entries(filters).filter(([_, value]) => value)
                );

                const response = await fetch('https://api.capitolgains.io/user/trades', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${idToken}`,  // Use ID token for authorization
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setTrades(data.disclosures);
            } else {
                throw new Error('Session is invalid or missing ID token');
            }
        } catch (error) {
            console.error('Error fetching recent trades:', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [filters]);

    useEffect(() => {
        fetchTrades();
    }, [fetchTrades]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchTrades();
    };

    const capitalizeWords = (str) => {
        if (!str) return ''; // Ensure the string is defined and not empty
        const capitalized = str.replace(/\b\w/g, char => char.toUpperCase());
        if (capitalized.length > 60) {
            return capitalized.slice(0, 60) + '...';
        }
        return capitalized;
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Recent Congressional Trades
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            name="year"
                            label="Year"
                            type="number"
                            value={filters.year}
                            onChange={handleFilterChange}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            name="first_name"
                            label="First Name"
                            value={filters.first_name}
                            onChange={handleFilterChange}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            name="last_name"
                            label="Last Name"
                            value={filters.last_name}
                            onChange={handleFilterChange}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Chamber</InputLabel>
                            <Select
                                name="chamber"
                                value={filters.chamber}
                                onChange={handleFilterChange}
                                label="Chamber"
                            >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="HOUSE">House</MenuItem>
                                <MenuItem value="SENATE">Senate</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Asset Code</InputLabel>
                            <Select
                                name="asset_code"
                                value={filters.asset_code}
                                onChange={handleFilterChange}
                                label="Asset Code"
                            >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="ST">Stocks</MenuItem>
                                <MenuItem value="OP">Options</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Transaction</InputLabel>
                            <Select
                                name="transaction"
                                value={filters.transaction}
                                onChange={handleFilterChange}
                                label="Transaction"
                            >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="purchase">Purchase</MenuItem>
                                <MenuItem value="sale">Sale</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {loading ? (
                <Grid container justifyContent="center" sx={{ mt: 4 }}>
                    <CircularProgress />
                </Grid>
            ) : error ? (
                <Alert severity="error">Error: {error.message}</Alert>
            ) : (
                <Grid container spacing={3} style={{ marginTop: '20px' }}>
                    {trades.map((trade, index) => {
                        const logoUrl = `https://img.logo.dev/ticker/${trade.ticker}?token=pk_Fy4ZV5RMQEKzNJfSy9h6BA`; // Image URL

                        return (
                            <Grid item xs={12} key={index}>
                                <Card sx={{ mb: 3, borderRadius: 2, boxShadow: 3 }}>
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                src={logoUrl}
                                                alt={`${trade.ticker} logo`}
                                                sx={{ width: 50, height: 50 }} // Adjust size as needed
                                            />
                                        }
                                        title={
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Typography 
                                                        variant="body1" 
                                                        sx={{ 
                                                            fontWeight: 'bold', 
                                                            color: '#1b1f21',  // Darker text color for title
                                                        }}
                                                    >
                                                        {`${capitalizeWords(trade.asset_name || '')} (${trade.ticker})`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography 
                                                        variant="body2" 
                                                        component="span" 
                                                        sx={{ 
                                                            fontWeight: 'bold', 
                                                            color: trade.transaction === 'purchase' ? '#4caf50' : '#1b1f21', // Green for purchase, darker for sale
                                                            textTransform: 'uppercase' 
                                                        }}
                                                    >
                                                        {trade.transaction}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        sx={{ bgcolor: 'primary.light' }}
                                    />
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                {trade.asset_code && (
                                                    <Typography 
                                                        variant="body2" 
                                                        color="textSecondary"
                                                        sx={{ textTransform: 'uppercase' }} // Ensuring all caps for asset code
                                                    >
                                                        <strong>Asset Code:</strong> {trade.asset_code === 'OP' ? 'Option' : 'Stock'}
                                                    </Typography>
                                                )}
                                                {trade.asset_code === 'OP' && trade.option_type && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Option Type:</strong> {trade.option_type === 'CALL' ? 'Call' : 'Put'}
                                                    </Typography>
                                                )}
                                                {trade.transaction_date && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Transaction Date:</strong> {trade.transaction_date}
                                                    </Typography>
                                                )}
                                                {trade.strike_price != null && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Strike Price:</strong> ${trade.strike_price.toFixed(2)}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {trade.first_name && trade.last_name && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Member:</strong> {trade.first_name} {trade.last_name} ({trade.governing_body})
                                                    </Typography>
                                                )}
                                                {trade.asset_value_low != null && trade.asset_value_high != null && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Asset Value:</strong> ${trade.asset_value_low.toLocaleString()} - ${trade.asset_value_high.toLocaleString()}
                                                    </Typography>
                                                )}
                                                {trade.stock_price != null && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Stock Price:</strong> ${trade.stock_price}
                                                    </Typography>
                                                )}
                                                {trade.stock_price_1yr != null && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Stock Price 1 Year Later:</strong> ${trade.stock_price_1yr}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {trade.stock_roi_1yr != null && (
                                            <Typography variant="body2" color={((trade.stock_roi_1yr - 1) * 100) > 0 ? 'success.main' : 'error.main'}>
                                                <strong>Stock ROI 1 Year Later:</strong> {((trade.stock_roi_1yr - 1) * 100).toFixed(2)}%
                                            </Typography>
                                        )}
                                        {trade.asset_code === 'OP' && trade.option_roi != null && (
                                            <Typography variant="body2" color={trade.option_roi > 0 ? 'success.main' : 'error.main'}>
                                                <strong>Option ROI:</strong> {(trade.option_roi * 100).toFixed(2)}%
                                            </Typography>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </Container>
    );
}

export default RecentTrades;